<template>
  <div class="order-examine-popup">
    <el-dialog title="确认付款" :close-on-click-modal="false" :visible.sync="visible" width="600px" center>
      <el-form :model="dataForm" ref="dataForm" size="small" label-width="120px">
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="申请单数量：" >
              <label>{{ dataForm.ids.length }}</label>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="总金额：" >
              <label>{{ dataForm.requestPayAmount }}</label>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="付款类型" prop="payType" :rules="[{ required: true, message: '付款类型不能为空', trigger: 'blur' }]">
              <el-select class="item-choose" v-model="dataForm.payType"
                         filterable clearable placeholder="请选择">
                <el-option label="企业转账(金额低于500元)" :value="1"></el-option>
                <el-option label="商户转账(金额高于500元)" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="付款密码" prop="payPassword" :rules="[{ required: true, message: '付款密码不能为空', trigger: 'blur' }]">
              <el-input v-model="dataForm.payPassword" type="password">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm" :loading="submitLoading">确 认</el-button>
        <el-button type="primary" @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import md5 from 'js-md5'
export default {
  data () {
    return {
      visible: false,
      submitLoading: false,
      dataForm: {
        payPassword: null,
        requestPayAmount: null,
        ids: [],
        payType: 1
      }
    }
  },
  methods: {
    init (ids, requestPayAmount) {
      this.visible = true
      this.dataForm.payPassword = null
      this.dataForm.requestPayAmount = requestPayAmount
      this.dataForm.ids = ids
    },
    confirm () {
      this.submitLoading = true
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const payPassword = md5.hex(this.dataForm.payPassword)
          this.$http({
            url: this.$http.adornUrl('/payRequest/wxPay'),
            method: 'POST',
            data: this.$http.adornData({ ids: this.dataForm.ids, payPassword: payPassword, payType: this.dataForm.payType })
          }).then(({ data }) => {
            if (data.length > 0) {
              let htmlStr = ''
              for (let i = 0; i < data.length; i++) {
                htmlStr += '<div>' + data[i] + '</div>'
              }
              this.$notify({
                title: '支付消息提示',
                offset: 120,
                type: 'warning',
                duration: 0,
                dangerouslyUseHTMLString: true,
                message: htmlStr
              })
              this.submitLoading = false
            } else {
              this.$message({
                message: '支付成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
              this.submitLoading = false
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
